@import "./common";

// Common 
@mixin sorting-icon {
    @media screen and (max-width: 768px) {
        .sorting.sorting.sorting::after {
            margin-right: 8px;
        }
    }
}

@mixin mobile-tablet-table($border-radius) {
    @media screen and (max-width: 768px) {
        thead {
            th:first-child {
                border-radius: $border-radius $border-radius 0 0;
                border-right: none;
            }

            th:last-child {
                border-radius: 0;
                border-left: none;
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-radius: 0;
                }

                td:last-child {
                    border-radius: 0 0 $border-radius $border-radius;
                }
            }
        }
    }
}

// End 

//Default Style - default-style = new name: Simple
.gswpts_default-style.gswpts_default-style {
    --header-bg-color: #ffffff;
    --header-text-color: #000000;
    --header-text-alignment: left;

    --body-bg-color: #ffffff;
    --body-text-color-col-1: #333333;
    --body-text-color-rest: #6B7280;

    --border-color: #e0e5f6;
    --pagination-colors: #828282;

    th.thead-item.expanded_style {
        text-align: var(--header-text-alignment);
    }

    //Header BG and text color
    thead th {
        background: var(--header-bg-color);
        color: var(--header-text-color);
    }

    th,
    td {
        border: none;
        border-bottom: 2px solid var(--border-color);
        background-color: var(--body-bg-color);
    }

    table.ui.celled.display {
        border: none;
    }

    .sorting.sorting.sorting::after {
        color: #acacac;
    }

    &.collapse_style {
        @include sorting-icon;
    }

    // fix forcefully editor end table issues 
    table.dataTable.row-border tbody th,
    table.dataTable.row-border tbody td,
    table.dataTable.display tbody th,
    table.dataTable.display tbody td {
        border-top: unset !important;
    }

    // First column text color 
    tbody td:nth-child(1) {
        color: var(--body-text-color-col-1);
    }

    // Rest column text color 
    tbody td:not(:first-child) {
        color: var(--body-text-color-rest);
    }


    .default-pagination-active .pagination.menu {
        padding: 0 !important;
        font-size: 14px;
        background: transparent !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        margin: 0 5px;

        // Pagination 
        &>* {

            &:first-child {
                border-radius: 0.28571429rem 0px 0px 0.28571429rem;
            }

            &:last-child {
                border-radius: 0px 0.28571429rem 0.28571429rem 0px 0px;
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.03);
            }

            @media screen and (max-width: 991px) {
                padding: 4px 12px !important;
            }

            @media screen and (max-width: 770px) {
                padding: 1px 9px !important;
            }

            @media screen and (max-width: 420px) {
                padding: 1px 6px !important;
                font-size: 12px;
            }

        }

        a {
            text-decoration: none;
        }

        .active {
            background-color: var(--pagination-colors);
            color: #ffffff;
        }
    }

    .default-pagination-active .paginate_button,
    .default-pagination-active .paginate_button.disabled,
    .default-pagination-active .ellipsis {
        font-size: 14px;
        border: none;
        /* margin: 0;
        padding: 9px 16px; */

        margin: 0px;
        padding: 5px 11px 5px 11px;

        font-weight: bold;
        color: #828282;
        background: transparent;
        border: 1px solid #E0E0E0;
        text-decoration: none;
        border-color: rgba(179, 179, 179, 0.3607843137);

        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
            border-color: #b3b3b35c;
            color: rgba(56, 13, 13, .787) !important; // Get the jquery color 
        }

        @media screen and (max-width: 770px) {
            padding: 6px 9px !important;
        }

        @media screen and (max-width: 420px) {
            padding: 3px 6px !important;
            font-size: 12px;
        }

    }



}

// Colored Column - style-1 : new name: vertical-style Vertical style
.gswpts_style-1.gswpts_style-1 {
    --header-bg-color: #6807f9;
    --header-text-color: #ffffff;

    --body-bg-color-even: #ffffff;
    --body-bg-color-odd: #E9E7FF;

    --body-text-color: #000;

    --border-color: #e0e5f6;
    --hover-bg-color: #EDE8FC;
    --pagination-colors: #5C51E0;

    table {
        background: var(--body-bg-color);

        th:first-child {
            border-radius: 10px 0 0 0;
            border-right: none;
        }

        th:last-child {
            border-radius: 0 10px 0 0;
            border-left: none;
        }

        // New add 
        tr:last-child td:first-child {
            border-radius: 0 0 0 10px;
        }

        tr:last-child td:last-child {
            border-radius: 0 0 10px 0;
        }

        // End 
    }

    thead th {
        background: var(--header-bg-color);
        color: var(--header-text-color);
    }

    td {
        color: var(--body-text-color) !important;
    }


    th.thead-item.expanded_style {
        text-align: left;
    }

    th,
    td {
        border: none;
        border-bottom: 1px solid var(--border-color);
    }

    tbody {
        td:nth-child(odd) {
            background: var(--body-bg-color-odd);
        }

        td:nth-child(even) {
            background: var(--body-bg-color-even);
        }
    }

    .sorting.sorting.sorting::after {
        color: #ffffff;
    }

    &.collapse_style {
        @include sorting-icon;
    }

    // NEW add 2 
    //Fid forcefully issues 
    table.dataTable.row-border tbody th,
    table.dataTable.row-border tbody td,
    table.dataTable.display tbody th,
    table.dataTable.display tbody td {
        border-top: 1px solid rgb(255 255 255 / 15%) !important;
    }

    table.dataTable.no-footer {
        border-bottom: unset !important;
    }

    // Pagination 
    .outlined-pagination-active .pagination.menu {
        padding: 0 !important;
        font-size: 14px;
        background: transparent !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        margin: 0 5px;

        // Pagination 
        &>* {

            &:hover {
                background-color: rgba(0, 0, 0, 0.03);
            }

            @media screen and (max-width: 991px) {
                padding: 4px 12px !important;
            }

            @media screen and (max-width: 770px) {
                padding: 1px 9px !important;
            }

            @media screen and (max-width: 420px) {
                padding: 1px 6px !important;
                font-size: 12px;
            }

        }

        a {
            text-decoration: none;
        }

        .active {
            background-color: #fff !important;
            border: 1px solid var(--pagination-colors) !important;
            border-radius: 5px !important;
            color: var(--pagination-colors) !important;
            margin: 3px 3px 2px 3px;
        }

        .previous {
            background-color: var(--pagination-colors) !important;
            border-radius: 5px !important;
            color: #fff !important;
        }

        .next {
            background-color: var(--pagination-colors) !important;
            border-radius: 5px !important;
            color: #fff !important;
        }
    }

    .outlined-pagination-active .paginate_button,
    .outlined-pagination-active .paginate_button.disabled,
    .outlined-pagination-active .ellipsis {
        font-size: 14px;
        border: none;
        /* margin: 0;
        padding: 9px 16px; */

        margin: 5px;
        padding: 5px 11px 5px 11px;

        font-weight: bold;

        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
            border-color: #b3b3b35c;
            color: rgba(56, 13, 13, .787) !important; // Get the jquery color 
        }

        @media screen and (max-width: 770px) {
            padding: 6px 9px !important;
        }

        @media screen and (max-width: 420px) {
            padding: 3px 6px !important;
            font-size: 12px;
        }

    }


    .paginate_button.item:nth-child(n+5):not(.next):not(.current):not(.active):not(.disabled):not(:nth-last-child(-n+2)) {
        display: none;
    }


}


// Stripped Table style-2 : new name: Minimal on dark 
.gswpts_style-2.gswpts_style-2 {
    --header-bg-color: #36304a;
    --header-text-color: #ffffff;

    --body-bg-color-even: #f5f5f5;
    --body-bg-color-odd: #ffffff;
    --body-text-color: #000000;
    --hover-bg-color: #d1d1d1;

    --pagination-colors: #261C3B;

    $border-radius: 10px;

    table {
        border-collapse: separate;

        th {
            background: var(--header-bg-color);
            color: var(--header-text-color);
            border-top: none;
            border-left: none;
            border-right: none;
            text-align: left;
        }

        //Body Text color 
        tbody tr td {
            color: var(--body-text-color) !important;
        }

        th:first-child {
            border-radius: $border-radius 0 0 0;
            border-right: none;
        }

        th:last-child {
            border-radius: 0 $border-radius 0 0;
            border-left: none;
        }

        tr:last-child td:first-child {
            border-radius: 0 0 0 $border-radius;
        }

        tr:last-child td:last-child {
            border-radius: 0 0 $border-radius 0;
        }

        td:first-child {
            border-right: none;
        }

        td:last-child {
            border-left: none;
        }

        td:not(:first-child, :last-child) {
            border-left: none;
            border-right: none;
        }

        td {
            border-top: none;
            border-bottom: none;
            border-left: 0.5px solid #ffffff;
            border-right: 0.5px solid #ffffff;
        }

        tr:nth-child(even)>td {
            background-color: var(--body-bg-color-even);
        }

        tr:nth-child(odd)>td {
            background-color: var(--body-bg-color-odd);
        }

        tbody {

            tr:hover td {
                background-color: var(--hover-bg-color);
            }
        }
    }

    table.ui.celled.display {
        border: none;
    }

    .sorting.sorting.sorting::after {
        color: #ffffff;
    }

    &.collapse_style {
        @include sorting-icon;

        @include mobile-tablet-table($border-radius);
    }

    //Fid forcefully issues 
    table.dataTable.row-border tbody th,
    table.dataTable.row-border tbody td,
    table.dataTable.display tbody th,
    table.dataTable.display tbody td {
        border-top: 1px solid rgb(255 255 255 / 15%) !important;
    }

    //pagination 

    .modern-pagination-active .pagination.menu {
        padding: 0 !important;
        font-size: 14px;
        background: transparent !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        margin: 0 5px;

        // Pagination 
        &>* {

            &:hover {
                background-color: rgba(0, 0, 0, 0.03);
            }

            @media screen and (max-width: 991px) {
                padding: 4px 12px !important;
            }

            @media screen and (max-width: 770px) {
                padding: 1px 9px !important;
            }

            @media screen and (max-width: 420px) {
                padding: 1px 6px !important;
                font-size: 12px;
            }

        }

        a {
            text-decoration: none;
            color: #000;
        }

        .active {
            background-color: var(--pagination-colors);
            color: #ffffff;
            border-radius: 50%;
        }

        .previous.disabled {
            opacity: 0.5;
        }

        .next.disabled {
            opacity: 0.5;
        }

    }

    .modern-pagination-active .paginate_button,
    .modern-pagination-active .paginate_button.disabled,
    .modern-pagination-active .ellipsis {
        font-size: 14px;
        border: none;
        /*  margin: 0;
        padding: 9px 16px; */

        margin: 5px;
        padding: 5px 11px 5px 11px;

        font-weight: bold;


        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
            border-color: #b3b3b35c;
            color: rgba(56, 13, 13, .787) !important; // Get the jquery color 
        }

        @media screen and (max-width: 770px) {
            padding: 6px 9px !important;
        }

        @media screen and (max-width: 420px) {
            padding: 3px 6px !important;
            font-size: 12px;
        }

    }



    .paginate_button.item:nth-child(n+5):not(.next):not(.current):not(.active):not(.disabled):not(:nth-last-child(-n+2)) {
        display: none;
    }

}

// Hover Style - style-3: new name: minimal-elegant-style Minimal elegant
.gswpts_style-3.gswpts_style-3 {
    --header-bg-color: #6c7ae0;
    --header-text-color: #ffffff;

    --body-bg-color-even: #f8f6ff;
    --body-bg-color-odd: #ffffff;
    --body-text-color: #000000;
    --hover-bg-color: #EDE8FC;

    --border-radius: 10px;
    --border-color: #fafafa;
    --pagination-colors: #2F80ED;

    $border-radius: 10px;

    table {
        border-collapse: separate;

        td,
        th {
            border: none;
            outline: none;
        }

        th {
            background-color: var(--header-bg-color);
            color: var(--header-text-color);
            border-top: none;
        }

        //Body Text color 
        tbody tr td {
            color: var(--body-text-color) !important;
        }


        th.thead-item.expanded_style {
            text-align: left;
        }

        th:first-child {
            border-radius: var(--border-radius) 0 0 0;
            border-right: none;
        }

        th:last-child {
            border-radius: 0 var(--border-radius) 0 0;
            border-left: none;
        }

        tr:last-child td:first-child {
            border-radius: 0 0 0 var(--border-radius);
        }

        tr:last-child td:last-child {
            border-radius: 0 0 var(--border-radius) 0;
        }

        td:first-child {
            border-right: none;
        }

        td:last-child {
            border-left: none;
        }

        td:not(:first-child, :last-child) {
            border-left: none;
            border-right: none;
        }

        th,
        td {
            border-top: none;
            border-bottom: none;
        }

        tr:nth-child(even) td {
            background-color: var(--body-bg-color-even);
        }

        tr:nth-child(odd) td {
            background-color: var(--body-bg-color-odd);
        }

        tbody {
            tr:hover td {
                background-color: var(--hover-bg-color);
            }
        }
    }

    // outer border remove 
    table.ui.celled.display {
        border: none;
    }

    .sorting.sorting.sorting::after {
        color: #ffffff;
    }

    &.collapse_style {
        @include sorting-icon;

        @include mobile-tablet-table($border-radius);
    }

    // Fix forcefully issues 
    table.dataTable.row-border tbody th,
    table.dataTable.row-border tbody td,
    table.dataTable.display tbody th,
    table.dataTable.display tbody td {
        border-top: 1px solid var(--border-color) !important;
    }

    //pagination 
    // Pagination 
    .outlined-pagination-active .pagination.menu {
        padding: 0 !important;
        font-size: 14px;
        background: transparent !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        margin: 0 5px;

        // Pagination 
        &>* {

            &:hover {
                background-color: rgba(0, 0, 0, 0.03);
            }

            @media screen and (max-width: 991px) {
                padding: 4px 12px !important;
            }

            @media screen and (max-width: 770px) {
                padding: 1px 9px !important;
            }

            @media screen and (max-width: 420px) {
                padding: 1px 6px !important;
                font-size: 12px;
            }

        }

        a {
            text-decoration: none;
        }

        .active {
            background-color: #fff !important;
            border: 1px solid var(--pagination-colors) !important;
            border-radius: 5px !important;
            color: var(--pagination-colors) !important;
            margin: 3px 3px 2px 3px;
        }

        .previous {
            background-color: var(--pagination-colors) !important;
            border-radius: 5px !important;
            color: #fff !important;
        }

        .next {
            background-color: var(--pagination-colors) !important;
            border-radius: 5px !important;
            color: #fff !important;
        }
    }

    .outlined-pagination-active .paginate_button,
    .outlined-pagination-active .paginate_button.disabled,
    .outlined-pagination-active .ellipsis {
        font-size: 14px;
        border: none;
        /* margin: 0;
        padding: 9px 16px; */

        margin: 5px;
        padding: 5px 11px 5px 11px;

        font-weight: bold;

        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
            border-color: #b3b3b35c;
            color: rgba(56, 13, 13, .787) !important; // Get the jquery color 
        }

        @media screen and (max-width: 770px) {
            padding: 6px 9px !important;
        }

        @media screen and (max-width: 420px) {
            padding: 3px 6px !important;
            font-size: 12px;
        }

    }

    .paginate_button.item:nth-child(n+5):not(.next):not(.current):not(.active):not(.disabled):not(:nth-last-child(-n+2)) {
        display: none;
    }

}

// Theme: Free Dark Table (Style 4) Simple on dark
.gswpts_style-4.gswpts_style-4.gswpts_style-4 {
    --header-bg-color: #000;
    --header-text-color: #ffffff;

    --body-bg-color: #000;
    --body-text-color: #ffffff;

    --hover-bg-color: #504949;
    --pagination-colors: #000;

    table {

        thead th,
        tbody tr td {
            background-color: var(--header-bg-color);
            color: var(--header-text-color);
            border: none;
        }

        tbody tr td {
            background-color: var(--body-bg-color);
            color: var(--body-text-color);
        }

        th.thead-item.expanded_style {
            text-align: left;
        }

        thead th {
            border: none;
        }

        thead,
        tbody {
            tr:hover {

                td,
                th {
                    background-color: var(--hover-bg-color);
                }
            }
        }

        a {
            color: orange;

            &:hover {
                color: #2ecc40;
            }
        }

        .sorting.sorting.sorting::after {
            color: #ffffff;
        }
    }

    &.collapse_style {
        @include sorting-icon;
    }

    // Pagination 

    .simple-pagination-active .pagination.menu {
        padding: 0 !important;
        font-size: 14px;
        background: transparent !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        margin: 0 5px;


        // Pagination 
        &>* {

            &:hover {
                background-color: rgba(0, 0, 0, 0.03);
            }

            @media screen and (max-width: 991px) {
                padding: 4px 12px !important;
            }

            @media screen and (max-width: 770px) {
                padding: 1px 9px !important;
            }

            @media screen and (max-width: 420px) {
                padding: 1px 6px !important;
                font-size: 12px;
            }

        }

        a {
            text-decoration: none;
            color: #000;
        }

        .active {
            background-color: var(--pagination-colors);
            color: #ffffff;
            border-radius: 4px;
        }

        .previous.disabled {
            opacity: 0.5;
        }

        .next.disabled {
            opacity: 0.5;
        }

    }

    .simple-pagination-active .paginate_button,
    .simple-pagination-active .paginate_button.disabled,
    .simple-pagination-active .ellipsis {
        font-size: 14px;
        border: none;
        /* margin: 0;
        padding: 9px 16px; */

        margin: 5px;
        padding: 5px 11px 5px 11px;

        font-weight: bold;

        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
            border-color: #b3b3b35c;
            color: rgba(56, 13, 13, .787) !important; // Get the jquery color 
        }

        @media screen and (max-width: 770px) {
            padding: 6px 9px !important;
        }

        @media screen and (max-width: 420px) {
            padding: 3px 6px !important;
            font-size: 12px;
        }

    }

    // Pagination 
    /*  #bottom_options #create_tables_paginate#create_tables_paginate {
        margin-left: 50%;
    } */

    .paginate_button.item:nth-child(n+5):not(.next):not(.current):not(.active):not(.disabled):not(:nth-last-child(-n+2)) {
        display: none;
    }

}

// Taliwind Style - style-5 : new name: Uppercase-heading  Uppercase heading
.gswpts_style-5.gswpts_style-5 {
    $border-radius: 10px;

    --header-bg-color: #F2F2F2;
    --header-text-color: #333333;

    --body-bg-color: #ffffff;
    --body-text-color: #000000;
    --hover-bg-color: #bdcfe4;
    --border-color: #e4e1e1;
    --pagination-colors: #2F80ED;

    --border-radius: 10px;

    table {
        border-collapse: separate;

        td,
        th {
            border: 1px solid var(--border-color);
        }

        th.thead-item.expanded_style {
            text-align: left;
        }

        thead th {
            text-transform: uppercase;
            background: var(--header-bg-color);
            color: var(--header-text-color);
        }

        tbody tr td {
            color: var(--body-text-color) !important;
        }

        td {
            border-top: none;
            background-color: var(--body-bg-color);
        }

        td,
        th {
            border-right: 1px solid var(--border-color);
            // border-right: 1px solid #f4f4f4;
        }

        td:last-child,
        th:last-child {
            border-right: 1px solid var(--border-color);
            // border-right: 1px solid #d8d8d8;
        }

        thead th:first-child {
            border-radius: var(--border-radius) 0 0 0;
        }

        thead th:last-child {
            border-radius: 0 var(--border-radius) 0 0;
        }

        tr:last-child td:first-child {
            border-radius: 0 0 0 var(--border-radius);
        }

        tr:last-child td:last-child {
            border-radius: 0 0 var(--border-radius) 0;
        }
    }

    // outer border remove
    table.ui.celled.display {
        border: none;
    }

    .sorting.sorting.sorting::after {
        color: #000;
    }

    &.collapse_style {
        @include sorting-icon;
        @include mobile-tablet-table($border-radius);

        @media screen and (max-width: 768px) {

            th,
            td {
                border-left: 1px solid var(--border-color) !important;
                border-right: 1px solid var(--border-color) !important;

                /* border-left: 1px solid rgba(229, 231, 235, 1) !important;
                border-right: 1px solid rgba(229, 231, 235, 1) !important; */
            }
        }
    }

    // Fix forcefully issues 
    table.dataTable.row-border tbody th,
    table.dataTable.row-border tbody td,
    table.dataTable.display tbody th,
    table.dataTable.display tbody td {
        border-top: 1px solid rgb(255 255 255 / 15%) !important;
    }

    // Pagination 
    .tailwind-pagination-active .pagination.menu {
        padding: 0 !important;
        font-size: 14px;
        background: transparent !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        margin: 0 5px;

        // Pagination 
        &>* {

            &:hover {
                background-color: rgba(0, 0, 0, 0.03);
            }

            @media screen and (max-width: 991px) {
                padding: 4px 12px !important;
            }

            @media screen and (max-width: 770px) {
                padding: 1px 9px !important;
            }

            @media screen and (max-width: 420px) {
                padding: 1px 6px !important;
                font-size: 12px;
            }

        }

        a {
            text-decoration: none;
        }

        .active {
            background-color: var(--pagination-colors);
            color: #ffffff;
        }

        .previous.disabled {
            opacity: 0.5;
        }

        .next.disabled {
            opacity: 0.5;
        }
    }

    .tailwind-pagination-active .paginate_button,
    .tailwind-pagination-active .paginate_button.disabled,
    .tailwind-pagination-active .ellipsis {
        font-size: 14px;
        border: none;
        /* margin: 0;
        padding: 9px 16px; */

        margin: 5px;
        padding: 5px 11px 5px 11px;

        font-weight: bold;

        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
            border-color: #b3b3b35c;
            color: rgba(56, 13, 13, .787) !important; // Get the jquery color 
        }

        @media screen and (max-width: 770px) {
            padding: 6px 9px !important;
        }

        @media screen and (max-width: 420px) {
            padding: 3px 6px !important;
            font-size: 12px;
        }

    }

    .paginate_button.item:nth-child(n+5):not(.next):not(.current):not(.active):not(.disabled):not(:nth-last-child(-n+2)) {
        display: none;
    }

}


// Minimal Table style-6 : new name: Minimal
.gswpts_style-6.gswpts_style-6 {
    --border-radius: 10px;
    --header-bg-color: #E5F1FF;
    --header-text-color: #0f0f0f;
    --body-bg-color-even: #EBF4FF;
    --body-bg-color-odd: #ffffff;
    --hover-bg-color: #bdcfe4;
    --body-text-color: #000000;
    --pagination-colors: #2BBAE8;

    $border-radius: 10px;

    table {
        border-collapse: separate;

        th {
            background: var(--header-bg-color);
            color: var(--header-text-color);
            border-top: none;
            border-left: none;
            border-right: none;
            text-align: left;
        }

        tbody tr td {
            color: var(--body-text-color) !important;
        }

        td:first-child {
            border-right: none;
        }

        td:last-child {
            border-left: none;
        }

        td:not(:first-child, :last-child) {
            border-left: none;
            border-right: none;
        }

        td {
            border-top: none;
            border-bottom: none;
            border-left: 0.5px solid #ffffff;
            border-right: 0.5px solid #ffffff;
        }

        tr:nth-child(even)>td {
            background-color: var(--body-bg-color-even);
        }

        tr:nth-child(odd)>td {
            background-color: var(--body-bg-color-odd);
        }

        tbody {
            tr:hover td {
                background-color: var(--hover-bg-color);
            }
        }
    }

    table.ui.celled.display {
        border: none;
    }

    .sorting.sorting.sorting::after {
        color: #ffffff;
    }

    &.collapse_style {
        @include sorting-icon;
        @include mobile-tablet-table($border-radius);
    }

    // Fix forcefully issues 
    table.dataTable.row-border tbody th,
    table.dataTable.row-border tbody td,
    table.dataTable.display tbody th,
    table.dataTable.display tbody td {
        border-top: 1px solid rgb(255 255 255 / 15%) !important;
    }

    // pagination style 
    // Pagination 
    .tailwind-pagination-active .pagination.menu {
        padding: 0 !important;
        font-size: 14px;
        background: transparent !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        margin: 0 5px;

        // Pagination 
        &>* {

            &:hover {
                background-color: rgba(0, 0, 0, 0.03);
            }

            @media screen and (max-width: 991px) {
                padding: 4px 12px !important;
            }

            @media screen and (max-width: 770px) {
                padding: 1px 9px !important;
            }

            @media screen and (max-width: 420px) {
                padding: 1px 6px !important;
                font-size: 12px;
            }

        }

        a {
            text-decoration: none;
            color: #000;
        }

        .active {
            background-color: var(--pagination-colors);
            color: #ffffff;
            border-radius: 4px;
        }

        .previous.disabled {
            opacity: 0.5;
        }

        .next.disabled {
            opacity: 0.5;
        }

    }

    .tailwind-pagination-active .paginate_button,
    .tailwind-pagination-active .paginate_button.disabled,
    .tailwind-pagination-active .ellipsis {
        font-size: 14px;
        border: none;
        /*  margin: 0;
        padding: 9px 16px; */

        margin: 5px;
        padding: 5px 11px 5px 11px;

        font-weight: bold;

        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
            border-color: #b3b3b35c;
            color: rgba(56, 13, 13, .787) !important; // Get the jquery color 
        }

        @media screen and (max-width: 770px) {
            padding: 6px 9px !important;
        }

        @media screen and (max-width: 420px) {
            padding: 3px 6px !important;
            font-size: 12px;
        }

    }

    .paginate_button.item:nth-child(n+5):not(.next):not(.current):not(.active):not(.disabled):not(:nth-last-child(-n+2)) {
        display: none;
    }

}



// Theme Dark Knight style gswpts_style-7
.gswpts_style-7.gswpts_style-7 {
    --header-bg-color: #8880F8;
    --header-text-color: #ffffff;

    --body-bg-color: #34344C;
    --body-text-color: #ffffff;

    --border-radius: 10px;
    --hover-bg-color: #7e78d3;
    --pagination-colors: #34344C;

    table {
        thead th {
            background-color: var(--header-bg-color);
            color: var(--header-text-color);
            border: none;
        }

        tbody tr td {
            background-color: var(--body-bg-color);
            color: var(--body-text-color) !important;
            border: none;
        }

        th.thead-item.expanded_style {
            text-align: left;
        }

        thead th {
            border: none;
        }

        thead th:first-child {
            border-radius: var(--border-radius) 0 0 0;
        }

        thead th:last-child {
            border-radius: 0 var(--border-radius) 0 0;
        }

        tr:last-child td:first-child {
            border-radius: 0 0 0 var(--border-radius);
        }

        tr:last-child td:last-child {
            border-radius: 0 0 var(--border-radius) 0;
        }

        thead,
        tbody {
            tr:hover {

                td,
                th {
                    background-color: var(--hover-bg-color);
                }
            }
        }

        a {
            color: orange;

            &:hover {
                color: #2ecc40;
            }
        }

        .sorting.sorting.sorting::after {
            color: #ffffff;
        }
    }

    &.collapse_style {
        @include sorting-icon;
    }

    // outer border remove
    table.ui.celled.display {
        border: none;
    }

    // Pagination 

    .simple-pagination-active .pagination.menu {
        padding: 0 !important;
        font-size: 14px;
        background: transparent !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        margin: 0 5px;

        // Pagination 
        &>* {

            &:hover {
                background-color: rgba(0, 0, 0, 0.03);
            }

            @media screen and (max-width: 991px) {
                padding: 4px 12px !important;
            }

            @media screen and (max-width: 770px) {
                padding: 1px 9px !important;
            }

            @media screen and (max-width: 420px) {
                padding: 1px 6px !important;
                font-size: 12px;
            }

        }

        a {
            text-decoration: none;
            color: #000;
        }

        .active {
            background-color: var(--pagination-colors);
            color: #ffffff;
            border-radius: 4px;
        }

        .previous.disabled {
            opacity: 0.5;
        }

        .next.disabled {
            opacity: 0.5;
        }

    }

    .simple-pagination-active .paginate_button,
    .simple-pagination-active .paginate_button.disabled,
    .simple-pagination-active .ellipsis {
        font-size: 14px;
        border: none;
        /* margin: 0;
        padding: 9px 16px; */
        margin: 5px;
        padding: 5px 11px 5px 11px;

        font-weight: bold;

        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
            border-color: #b3b3b35c;
            color: rgba(56, 13, 13, .787) !important; // Get the jquery color 
        }

        @media screen and (max-width: 770px) {
            padding: 6px 9px !important;
        }

        @media screen and (max-width: 420px) {
            padding: 3px 6px !important;
            font-size: 12px;
        }

    }

    // Pagination 
    /* #bottom_options #create_tables_paginate#create_tables_paginate {
        margin-left: 50%;
    } */

    .paginate_button.item:nth-child(n+5):not(.next):not(.current):not(.active):not(.disabled):not(:nth-last-child(-n+2)) {
        display: none;
    }

}

// Uppercase elegant style new style - style-8
.gswpts_style-8.gswpts_style-8 {
    --header-bg-color: #E0E7FF;
    --header-text-color: #312E81;

    --border-radius: 10px;
    --body-bg-color: #ffffff;
    --body-text-color: #333333;

    --body-text-color-col-1: #333333;
    --body-text-color-rest: #6B7280;

    --hover-bg-color: #e4e9f8;

    --border-color: #EEF2FF;
    --pagination-colors: #5C51E0;

    $border-radius: 10px;

    table {
        border-collapse: unset;

        thead th,
        tbody tr td {
            background-color: var(--header-bg-color);
            color: var(--header-text-color);
            border: none;
        }

        tbody tr td {
            background-color: var(--body-bg-color);
            color: var(--body-text-color);
        }

        th.thead-item.expanded_style {
            text-align: left;
        }

        tbody td:first-child {
            color: var(--body-text-color-col-1);
        }

        tbody td:not(:first-child) {
            color: var(--body-text-color-rest);
            font-weight: 400;
        }


        thead th {
            border: none;
            text-transform: uppercase;
            font-weight: 600;
        }

        // Heading corner border 
        thead th:first-child {
            border-radius: var(--border-radius) 0 0 0;
        }

        thead th:last-child {
            border-radius: 0 var(--border-radius) 0 0;
        }

        tr:last-child td:first-child {
            border-radius: 0 0 0 var(--border-radius);
        }

        tr:last-child td:last-child {
            border-radius: 0 0 var(--border-radius) 0;
        }



        tbody tr td:first-child {
            border-left: 2px solid var(--border-color);
        }

        tbody tr td:last-child {
            border-right: 2px solid var(--border-color);
        }

        tbody tr:last-child td {
            border-bottom: 2px solid var(--border-color);
        }

        thead,
        tbody {
            tr:hover {

                td,
                th {
                    background-color: var(--hover-bg-color);
                }
            }
        }

        a {
            color: orange;

            &:hover {
                color: #2ecc40;
            }
        }

        .sorting.sorting.sorting::after {
            color: #ffffff;
        }
    }

    &.collapse_style {
        @include sorting-icon;
    }

    // outer border remove
    table.ui.celled.display {
        border: none;
    }

    // Pagination 
    .tailwind-pagination-active .pagination.menu {
        padding: 0 !important;
        font-size: 14px;
        background: transparent !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border: none;
        margin: 0 5px;

        // Pagination 
        &>* {

            &:hover {
                background-color: rgba(0, 0, 0, 0.03);
            }

            @media screen and (max-width: 991px) {
                padding: 4px 12px !important;
            }

            @media screen and (max-width: 770px) {
                padding: 1px 9px !important;
            }

            @media screen and (max-width: 420px) {
                padding: 1px 6px !important;
                font-size: 12px;
            }

        }

        a {
            text-decoration: none;
        }

        .active {
            background-color: var(--pagination-colors);
            color: #ffffff;
        }

        .previous.disabled {
            opacity: 0.5;
        }

        .next.disabled {
            opacity: 0.5;
        }
    }

    .tailwind-pagination-active .paginate_button,
    .tailwind-pagination-active .paginate_button.disabled,
    .tailwind-pagination-active .ellipsis {
        font-size: 14px;
        border: none;
        /* margin: 0;
        padding: 9px 16px; */

        margin: 5px;
        padding: 5px 11px 5px 11px;

        font-weight: bold;

        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
            border-color: #b3b3b35c;
            color: rgba(56, 13, 13, .787) !important; // Get the jquery color 
        }

        @media screen and (max-width: 770px) {
            padding: 6px 9px !important;
        }

        @media screen and (max-width: 420px) {
            padding: 3px 6px !important;
            font-size: 12px;
        }

    }

    .paginate_button.item:nth-child(n+5):not(.next):not(.current):not(.active):not(.disabled):not(:nth-last-child(-n+2)) {
        display: none;
    }

}


// New other theme style add here 