.gswpts_default-style.gswpts_default-style {
  --header-bg-color: #ffffff;
  --header-text-color: #000000;
  --header-text-alignment: left;
  --body-bg-color: #ffffff;
  --body-text-color-col-1: #333333;
  --body-text-color-rest: #6B7280;
  --border-color: #e0e5f6;
  --pagination-colors: #828282;
}
.gswpts_default-style.gswpts_default-style th.thead-item.expanded_style {
  text-align: var(--header-text-alignment);
}
.gswpts_default-style.gswpts_default-style thead th {
  background: var(--header-bg-color);
  color: var(--header-text-color);
}
.gswpts_default-style.gswpts_default-style th,
.gswpts_default-style.gswpts_default-style td {
  border: none;
  border-bottom: 2px solid var(--border-color);
  background-color: var(--body-bg-color);
}
.gswpts_default-style.gswpts_default-style table.ui.celled.display {
  border: none;
}
.gswpts_default-style.gswpts_default-style .sorting.sorting.sorting::after {
  color: #acacac;
}
@media screen and (max-width: 768px) {
  .gswpts_default-style.gswpts_default-style.collapse_style .sorting.sorting.sorting::after {
    margin-right: 8px;
  }
}
.gswpts_default-style.gswpts_default-style table.dataTable.row-border tbody th,
.gswpts_default-style.gswpts_default-style table.dataTable.row-border tbody td,
.gswpts_default-style.gswpts_default-style table.dataTable.display tbody th,
.gswpts_default-style.gswpts_default-style table.dataTable.display tbody td {
  border-top: unset !important;
}
.gswpts_default-style.gswpts_default-style tbody td:nth-child(1) {
  color: var(--body-text-color-col-1);
}
.gswpts_default-style.gswpts_default-style tbody td:not(:first-child) {
  color: var(--body-text-color-rest);
}
.gswpts_default-style.gswpts_default-style .default-pagination-active .pagination.menu {
  padding: 0 !important;
  font-size: 14px;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: none;
  margin: 0 5px;
}
.gswpts_default-style.gswpts_default-style .default-pagination-active .pagination.menu > *:first-child {
  border-radius: 0.28571429rem 0px 0px 0.28571429rem;
}
.gswpts_default-style.gswpts_default-style .default-pagination-active .pagination.menu > *:last-child {
  border-radius: 0px 0.28571429rem 0.28571429rem 0px 0px;
}
.gswpts_default-style.gswpts_default-style .default-pagination-active .pagination.menu > *:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
@media screen and (max-width: 991px) {
  .gswpts_default-style.gswpts_default-style .default-pagination-active .pagination.menu > * {
    padding: 4px 12px !important;
  }
}
@media screen and (max-width: 770px) {
  .gswpts_default-style.gswpts_default-style .default-pagination-active .pagination.menu > * {
    padding: 1px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_default-style.gswpts_default-style .default-pagination-active .pagination.menu > * {
    padding: 1px 6px !important;
    font-size: 12px;
  }
}
.gswpts_default-style.gswpts_default-style .default-pagination-active .pagination.menu a {
  text-decoration: none;
}
.gswpts_default-style.gswpts_default-style .default-pagination-active .pagination.menu .active {
  background-color: var(--pagination-colors);
  color: #ffffff;
}
.gswpts_default-style.gswpts_default-style .default-pagination-active .paginate_button,
.gswpts_default-style.gswpts_default-style .default-pagination-active .paginate_button.disabled,
.gswpts_default-style.gswpts_default-style .default-pagination-active .ellipsis {
  font-size: 14px;
  border: none;
  /* margin: 0;
  padding: 9px 16px; */
  margin: 0px;
  padding: 5px 11px 5px 11px;
  font-weight: bold;
  color: #828282;
  background: transparent;
  border: 1px solid #E0E0E0;
  text-decoration: none;
  border-color: rgba(179, 179, 179, 0.3607843137);
}
.gswpts_default-style.gswpts_default-style .default-pagination-active .paginate_button:hover,
.gswpts_default-style.gswpts_default-style .default-pagination-active .paginate_button.disabled:hover,
.gswpts_default-style.gswpts_default-style .default-pagination-active .ellipsis:hover {
  background-color: rgba(0, 0, 0, 0.03);
  border-color: rgba(179, 179, 179, 0.3607843137);
  color: rgba(56, 13, 13, 0.787) !important;
}
@media screen and (max-width: 770px) {
  .gswpts_default-style.gswpts_default-style .default-pagination-active .paginate_button,
  .gswpts_default-style.gswpts_default-style .default-pagination-active .paginate_button.disabled,
  .gswpts_default-style.gswpts_default-style .default-pagination-active .ellipsis {
    padding: 6px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_default-style.gswpts_default-style .default-pagination-active .paginate_button,
  .gswpts_default-style.gswpts_default-style .default-pagination-active .paginate_button.disabled,
  .gswpts_default-style.gswpts_default-style .default-pagination-active .ellipsis {
    padding: 3px 6px !important;
    font-size: 12px;
  }
}

.gswpts_style-1.gswpts_style-1 {
  --header-bg-color: #6807f9;
  --header-text-color: #ffffff;
  --body-bg-color-even: #ffffff;
  --body-bg-color-odd: #E9E7FF;
  --body-text-color: #000;
  --border-color: #e0e5f6;
  --hover-bg-color: #EDE8FC;
  --pagination-colors: #5C51E0;
}
.gswpts_style-1.gswpts_style-1 table {
  background: var(--body-bg-color);
}
.gswpts_style-1.gswpts_style-1 table th:first-child {
  border-radius: 10px 0 0 0;
  border-right: none;
}
.gswpts_style-1.gswpts_style-1 table th:last-child {
  border-radius: 0 10px 0 0;
  border-left: none;
}
.gswpts_style-1.gswpts_style-1 table tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}
.gswpts_style-1.gswpts_style-1 table tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}
.gswpts_style-1.gswpts_style-1 thead th {
  background: var(--header-bg-color);
  color: var(--header-text-color);
}
.gswpts_style-1.gswpts_style-1 td {
  color: var(--body-text-color) !important;
}
.gswpts_style-1.gswpts_style-1 th.thead-item.expanded_style {
  text-align: left;
}
.gswpts_style-1.gswpts_style-1 th,
.gswpts_style-1.gswpts_style-1 td {
  border: none;
  border-bottom: 1px solid var(--border-color);
}
.gswpts_style-1.gswpts_style-1 tbody td:nth-child(odd) {
  background: var(--body-bg-color-odd);
}
.gswpts_style-1.gswpts_style-1 tbody td:nth-child(even) {
  background: var(--body-bg-color-even);
}
.gswpts_style-1.gswpts_style-1 .sorting.sorting.sorting::after {
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .gswpts_style-1.gswpts_style-1.collapse_style .sorting.sorting.sorting::after {
    margin-right: 8px;
  }
}
.gswpts_style-1.gswpts_style-1 table.dataTable.row-border tbody th,
.gswpts_style-1.gswpts_style-1 table.dataTable.row-border tbody td,
.gswpts_style-1.gswpts_style-1 table.dataTable.display tbody th,
.gswpts_style-1.gswpts_style-1 table.dataTable.display tbody td {
  border-top: 1px solid rgba(255, 255, 255, 0.15) !important;
}
.gswpts_style-1.gswpts_style-1 table.dataTable.no-footer {
  border-bottom: unset !important;
}
.gswpts_style-1.gswpts_style-1 .outlined-pagination-active .pagination.menu {
  padding: 0 !important;
  font-size: 14px;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: none;
  margin: 0 5px;
}
.gswpts_style-1.gswpts_style-1 .outlined-pagination-active .pagination.menu > *:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
@media screen and (max-width: 991px) {
  .gswpts_style-1.gswpts_style-1 .outlined-pagination-active .pagination.menu > * {
    padding: 4px 12px !important;
  }
}
@media screen and (max-width: 770px) {
  .gswpts_style-1.gswpts_style-1 .outlined-pagination-active .pagination.menu > * {
    padding: 1px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_style-1.gswpts_style-1 .outlined-pagination-active .pagination.menu > * {
    padding: 1px 6px !important;
    font-size: 12px;
  }
}
.gswpts_style-1.gswpts_style-1 .outlined-pagination-active .pagination.menu a {
  text-decoration: none;
}
.gswpts_style-1.gswpts_style-1 .outlined-pagination-active .pagination.menu .active {
  background-color: #fff !important;
  border: 1px solid var(--pagination-colors) !important;
  border-radius: 5px !important;
  color: var(--pagination-colors) !important;
  margin: 3px 3px 2px 3px;
}
.gswpts_style-1.gswpts_style-1 .outlined-pagination-active .pagination.menu .previous {
  background-color: var(--pagination-colors) !important;
  border-radius: 5px !important;
  color: #fff !important;
}
.gswpts_style-1.gswpts_style-1 .outlined-pagination-active .pagination.menu .next {
  background-color: var(--pagination-colors) !important;
  border-radius: 5px !important;
  color: #fff !important;
}
.gswpts_style-1.gswpts_style-1 .outlined-pagination-active .paginate_button,
.gswpts_style-1.gswpts_style-1 .outlined-pagination-active .paginate_button.disabled,
.gswpts_style-1.gswpts_style-1 .outlined-pagination-active .ellipsis {
  font-size: 14px;
  border: none;
  /* margin: 0;
  padding: 9px 16px; */
  margin: 5px;
  padding: 5px 11px 5px 11px;
  font-weight: bold;
}
.gswpts_style-1.gswpts_style-1 .outlined-pagination-active .paginate_button:hover,
.gswpts_style-1.gswpts_style-1 .outlined-pagination-active .paginate_button.disabled:hover,
.gswpts_style-1.gswpts_style-1 .outlined-pagination-active .ellipsis:hover {
  background-color: rgba(0, 0, 0, 0.03);
  border-color: rgba(179, 179, 179, 0.3607843137);
  color: rgba(56, 13, 13, 0.787) !important;
}
@media screen and (max-width: 770px) {
  .gswpts_style-1.gswpts_style-1 .outlined-pagination-active .paginate_button,
  .gswpts_style-1.gswpts_style-1 .outlined-pagination-active .paginate_button.disabled,
  .gswpts_style-1.gswpts_style-1 .outlined-pagination-active .ellipsis {
    padding: 6px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_style-1.gswpts_style-1 .outlined-pagination-active .paginate_button,
  .gswpts_style-1.gswpts_style-1 .outlined-pagination-active .paginate_button.disabled,
  .gswpts_style-1.gswpts_style-1 .outlined-pagination-active .ellipsis {
    padding: 3px 6px !important;
    font-size: 12px;
  }
}
.gswpts_style-1.gswpts_style-1 .paginate_button.item:nth-child(n+5):not(.next):not(.current):not(.active):not(.disabled):not(:nth-last-child(-n+2)) {
  display: none;
}

.gswpts_style-2.gswpts_style-2 {
  --header-bg-color: #36304a;
  --header-text-color: #ffffff;
  --body-bg-color-even: #f5f5f5;
  --body-bg-color-odd: #ffffff;
  --body-text-color: #000000;
  --hover-bg-color: #d1d1d1;
  --pagination-colors: #261C3B;
}
.gswpts_style-2.gswpts_style-2 table {
  border-collapse: separate;
}
.gswpts_style-2.gswpts_style-2 table th {
  background: var(--header-bg-color);
  color: var(--header-text-color);
  border-top: none;
  border-left: none;
  border-right: none;
  text-align: left;
}
.gswpts_style-2.gswpts_style-2 table tbody tr td {
  color: var(--body-text-color) !important;
}
.gswpts_style-2.gswpts_style-2 table th:first-child {
  border-radius: 10px 0 0 0;
  border-right: none;
}
.gswpts_style-2.gswpts_style-2 table th:last-child {
  border-radius: 0 10px 0 0;
  border-left: none;
}
.gswpts_style-2.gswpts_style-2 table tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}
.gswpts_style-2.gswpts_style-2 table tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}
.gswpts_style-2.gswpts_style-2 table td:first-child {
  border-right: none;
}
.gswpts_style-2.gswpts_style-2 table td:last-child {
  border-left: none;
}
.gswpts_style-2.gswpts_style-2 table td:not(:first-child, :last-child) {
  border-left: none;
  border-right: none;
}
.gswpts_style-2.gswpts_style-2 table td {
  border-top: none;
  border-bottom: none;
  border-left: 0.5px solid #ffffff;
  border-right: 0.5px solid #ffffff;
}
.gswpts_style-2.gswpts_style-2 table tr:nth-child(even) > td {
  background-color: var(--body-bg-color-even);
}
.gswpts_style-2.gswpts_style-2 table tr:nth-child(odd) > td {
  background-color: var(--body-bg-color-odd);
}
.gswpts_style-2.gswpts_style-2 table tbody tr:hover td {
  background-color: var(--hover-bg-color);
}
.gswpts_style-2.gswpts_style-2 table.ui.celled.display {
  border: none;
}
.gswpts_style-2.gswpts_style-2 .sorting.sorting.sorting::after {
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .gswpts_style-2.gswpts_style-2.collapse_style .sorting.sorting.sorting::after {
    margin-right: 8px;
  }
}
@media screen and (max-width: 768px) {
  .gswpts_style-2.gswpts_style-2.collapse_style thead th:first-child {
    border-radius: 10px 10px 0 0;
    border-right: none;
  }
  .gswpts_style-2.gswpts_style-2.collapse_style thead th:last-child {
    border-radius: 0;
    border-left: none;
  }
  .gswpts_style-2.gswpts_style-2.collapse_style tbody tr:last-child td:first-child {
    border-radius: 0;
  }
  .gswpts_style-2.gswpts_style-2.collapse_style tbody tr:last-child td:last-child {
    border-radius: 0 0 10px 10px;
  }
}
.gswpts_style-2.gswpts_style-2 table.dataTable.row-border tbody th,
.gswpts_style-2.gswpts_style-2 table.dataTable.row-border tbody td,
.gswpts_style-2.gswpts_style-2 table.dataTable.display tbody th,
.gswpts_style-2.gswpts_style-2 table.dataTable.display tbody td {
  border-top: 1px solid rgba(255, 255, 255, 0.15) !important;
}
.gswpts_style-2.gswpts_style-2 .modern-pagination-active .pagination.menu {
  padding: 0 !important;
  font-size: 14px;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: none;
  margin: 0 5px;
}
.gswpts_style-2.gswpts_style-2 .modern-pagination-active .pagination.menu > *:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
@media screen and (max-width: 991px) {
  .gswpts_style-2.gswpts_style-2 .modern-pagination-active .pagination.menu > * {
    padding: 4px 12px !important;
  }
}
@media screen and (max-width: 770px) {
  .gswpts_style-2.gswpts_style-2 .modern-pagination-active .pagination.menu > * {
    padding: 1px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_style-2.gswpts_style-2 .modern-pagination-active .pagination.menu > * {
    padding: 1px 6px !important;
    font-size: 12px;
  }
}
.gswpts_style-2.gswpts_style-2 .modern-pagination-active .pagination.menu a {
  text-decoration: none;
  color: #000;
}
.gswpts_style-2.gswpts_style-2 .modern-pagination-active .pagination.menu .active {
  background-color: var(--pagination-colors);
  color: #ffffff;
  border-radius: 50%;
}
.gswpts_style-2.gswpts_style-2 .modern-pagination-active .pagination.menu .previous.disabled {
  opacity: 0.5;
}
.gswpts_style-2.gswpts_style-2 .modern-pagination-active .pagination.menu .next.disabled {
  opacity: 0.5;
}
.gswpts_style-2.gswpts_style-2 .modern-pagination-active .paginate_button,
.gswpts_style-2.gswpts_style-2 .modern-pagination-active .paginate_button.disabled,
.gswpts_style-2.gswpts_style-2 .modern-pagination-active .ellipsis {
  font-size: 14px;
  border: none;
  /*  margin: 0;
  padding: 9px 16px; */
  margin: 5px;
  padding: 5px 11px 5px 11px;
  font-weight: bold;
}
.gswpts_style-2.gswpts_style-2 .modern-pagination-active .paginate_button:hover,
.gswpts_style-2.gswpts_style-2 .modern-pagination-active .paginate_button.disabled:hover,
.gswpts_style-2.gswpts_style-2 .modern-pagination-active .ellipsis:hover {
  background-color: rgba(0, 0, 0, 0.03);
  border-color: rgba(179, 179, 179, 0.3607843137);
  color: rgba(56, 13, 13, 0.787) !important;
}
@media screen and (max-width: 770px) {
  .gswpts_style-2.gswpts_style-2 .modern-pagination-active .paginate_button,
  .gswpts_style-2.gswpts_style-2 .modern-pagination-active .paginate_button.disabled,
  .gswpts_style-2.gswpts_style-2 .modern-pagination-active .ellipsis {
    padding: 6px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_style-2.gswpts_style-2 .modern-pagination-active .paginate_button,
  .gswpts_style-2.gswpts_style-2 .modern-pagination-active .paginate_button.disabled,
  .gswpts_style-2.gswpts_style-2 .modern-pagination-active .ellipsis {
    padding: 3px 6px !important;
    font-size: 12px;
  }
}
.gswpts_style-2.gswpts_style-2 .paginate_button.item:nth-child(n+5):not(.next):not(.current):not(.active):not(.disabled):not(:nth-last-child(-n+2)) {
  display: none;
}

.gswpts_style-3.gswpts_style-3 {
  --header-bg-color: #6c7ae0;
  --header-text-color: #ffffff;
  --body-bg-color-even: #f8f6ff;
  --body-bg-color-odd: #ffffff;
  --body-text-color: #000000;
  --hover-bg-color: #EDE8FC;
  --border-radius: 10px;
  --border-color: #fafafa;
  --pagination-colors: #2F80ED;
}
.gswpts_style-3.gswpts_style-3 table {
  border-collapse: separate;
}
.gswpts_style-3.gswpts_style-3 table td,
.gswpts_style-3.gswpts_style-3 table th {
  border: none;
  outline: none;
}
.gswpts_style-3.gswpts_style-3 table th {
  background-color: var(--header-bg-color);
  color: var(--header-text-color);
  border-top: none;
}
.gswpts_style-3.gswpts_style-3 table tbody tr td {
  color: var(--body-text-color) !important;
}
.gswpts_style-3.gswpts_style-3 table th.thead-item.expanded_style {
  text-align: left;
}
.gswpts_style-3.gswpts_style-3 table th:first-child {
  border-radius: var(--border-radius) 0 0 0;
  border-right: none;
}
.gswpts_style-3.gswpts_style-3 table th:last-child {
  border-radius: 0 var(--border-radius) 0 0;
  border-left: none;
}
.gswpts_style-3.gswpts_style-3 table tr:last-child td:first-child {
  border-radius: 0 0 0 var(--border-radius);
}
.gswpts_style-3.gswpts_style-3 table tr:last-child td:last-child {
  border-radius: 0 0 var(--border-radius) 0;
}
.gswpts_style-3.gswpts_style-3 table td:first-child {
  border-right: none;
}
.gswpts_style-3.gswpts_style-3 table td:last-child {
  border-left: none;
}
.gswpts_style-3.gswpts_style-3 table td:not(:first-child, :last-child) {
  border-left: none;
  border-right: none;
}
.gswpts_style-3.gswpts_style-3 table th,
.gswpts_style-3.gswpts_style-3 table td {
  border-top: none;
  border-bottom: none;
}
.gswpts_style-3.gswpts_style-3 table tr:nth-child(even) td {
  background-color: var(--body-bg-color-even);
}
.gswpts_style-3.gswpts_style-3 table tr:nth-child(odd) td {
  background-color: var(--body-bg-color-odd);
}
.gswpts_style-3.gswpts_style-3 table tbody tr:hover td {
  background-color: var(--hover-bg-color);
}
.gswpts_style-3.gswpts_style-3 table.ui.celled.display {
  border: none;
}
.gswpts_style-3.gswpts_style-3 .sorting.sorting.sorting::after {
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .gswpts_style-3.gswpts_style-3.collapse_style .sorting.sorting.sorting::after {
    margin-right: 8px;
  }
}
@media screen and (max-width: 768px) {
  .gswpts_style-3.gswpts_style-3.collapse_style thead th:first-child {
    border-radius: 10px 10px 0 0;
    border-right: none;
  }
  .gswpts_style-3.gswpts_style-3.collapse_style thead th:last-child {
    border-radius: 0;
    border-left: none;
  }
  .gswpts_style-3.gswpts_style-3.collapse_style tbody tr:last-child td:first-child {
    border-radius: 0;
  }
  .gswpts_style-3.gswpts_style-3.collapse_style tbody tr:last-child td:last-child {
    border-radius: 0 0 10px 10px;
  }
}
.gswpts_style-3.gswpts_style-3 table.dataTable.row-border tbody th,
.gswpts_style-3.gswpts_style-3 table.dataTable.row-border tbody td,
.gswpts_style-3.gswpts_style-3 table.dataTable.display tbody th,
.gswpts_style-3.gswpts_style-3 table.dataTable.display tbody td {
  border-top: 1px solid var(--border-color) !important;
}
.gswpts_style-3.gswpts_style-3 .outlined-pagination-active .pagination.menu {
  padding: 0 !important;
  font-size: 14px;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: none;
  margin: 0 5px;
}
.gswpts_style-3.gswpts_style-3 .outlined-pagination-active .pagination.menu > *:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
@media screen and (max-width: 991px) {
  .gswpts_style-3.gswpts_style-3 .outlined-pagination-active .pagination.menu > * {
    padding: 4px 12px !important;
  }
}
@media screen and (max-width: 770px) {
  .gswpts_style-3.gswpts_style-3 .outlined-pagination-active .pagination.menu > * {
    padding: 1px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_style-3.gswpts_style-3 .outlined-pagination-active .pagination.menu > * {
    padding: 1px 6px !important;
    font-size: 12px;
  }
}
.gswpts_style-3.gswpts_style-3 .outlined-pagination-active .pagination.menu a {
  text-decoration: none;
}
.gswpts_style-3.gswpts_style-3 .outlined-pagination-active .pagination.menu .active {
  background-color: #fff !important;
  border: 1px solid var(--pagination-colors) !important;
  border-radius: 5px !important;
  color: var(--pagination-colors) !important;
  margin: 3px 3px 2px 3px;
}
.gswpts_style-3.gswpts_style-3 .outlined-pagination-active .pagination.menu .previous {
  background-color: var(--pagination-colors) !important;
  border-radius: 5px !important;
  color: #fff !important;
}
.gswpts_style-3.gswpts_style-3 .outlined-pagination-active .pagination.menu .next {
  background-color: var(--pagination-colors) !important;
  border-radius: 5px !important;
  color: #fff !important;
}
.gswpts_style-3.gswpts_style-3 .outlined-pagination-active .paginate_button,
.gswpts_style-3.gswpts_style-3 .outlined-pagination-active .paginate_button.disabled,
.gswpts_style-3.gswpts_style-3 .outlined-pagination-active .ellipsis {
  font-size: 14px;
  border: none;
  /* margin: 0;
  padding: 9px 16px; */
  margin: 5px;
  padding: 5px 11px 5px 11px;
  font-weight: bold;
}
.gswpts_style-3.gswpts_style-3 .outlined-pagination-active .paginate_button:hover,
.gswpts_style-3.gswpts_style-3 .outlined-pagination-active .paginate_button.disabled:hover,
.gswpts_style-3.gswpts_style-3 .outlined-pagination-active .ellipsis:hover {
  background-color: rgba(0, 0, 0, 0.03);
  border-color: rgba(179, 179, 179, 0.3607843137);
  color: rgba(56, 13, 13, 0.787) !important;
}
@media screen and (max-width: 770px) {
  .gswpts_style-3.gswpts_style-3 .outlined-pagination-active .paginate_button,
  .gswpts_style-3.gswpts_style-3 .outlined-pagination-active .paginate_button.disabled,
  .gswpts_style-3.gswpts_style-3 .outlined-pagination-active .ellipsis {
    padding: 6px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_style-3.gswpts_style-3 .outlined-pagination-active .paginate_button,
  .gswpts_style-3.gswpts_style-3 .outlined-pagination-active .paginate_button.disabled,
  .gswpts_style-3.gswpts_style-3 .outlined-pagination-active .ellipsis {
    padding: 3px 6px !important;
    font-size: 12px;
  }
}
.gswpts_style-3.gswpts_style-3 .paginate_button.item:nth-child(n+5):not(.next):not(.current):not(.active):not(.disabled):not(:nth-last-child(-n+2)) {
  display: none;
}

.gswpts_style-4.gswpts_style-4.gswpts_style-4 {
  --header-bg-color: #000;
  --header-text-color: #ffffff;
  --body-bg-color: #000;
  --body-text-color: #ffffff;
  --hover-bg-color: #504949;
  --pagination-colors: #000;
  /*  #bottom_options #create_tables_paginate#create_tables_paginate {
      margin-left: 50%;
  } */
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 table thead th,
.gswpts_style-4.gswpts_style-4.gswpts_style-4 table tbody tr td {
  background-color: var(--header-bg-color);
  color: var(--header-text-color);
  border: none;
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 table tbody tr td {
  background-color: var(--body-bg-color);
  color: var(--body-text-color);
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 table th.thead-item.expanded_style {
  text-align: left;
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 table thead th {
  border: none;
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 table thead tr:hover td,
.gswpts_style-4.gswpts_style-4.gswpts_style-4 table thead tr:hover th,
.gswpts_style-4.gswpts_style-4.gswpts_style-4 table tbody tr:hover td,
.gswpts_style-4.gswpts_style-4.gswpts_style-4 table tbody tr:hover th {
  background-color: var(--hover-bg-color);
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 table a {
  color: orange;
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 table a:hover {
  color: #2ecc40;
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 table .sorting.sorting.sorting::after {
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .gswpts_style-4.gswpts_style-4.gswpts_style-4.collapse_style .sorting.sorting.sorting::after {
    margin-right: 8px;
  }
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .pagination.menu {
  padding: 0 !important;
  font-size: 14px;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: none;
  margin: 0 5px;
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .pagination.menu > *:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
@media screen and (max-width: 991px) {
  .gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .pagination.menu > * {
    padding: 4px 12px !important;
  }
}
@media screen and (max-width: 770px) {
  .gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .pagination.menu > * {
    padding: 1px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .pagination.menu > * {
    padding: 1px 6px !important;
    font-size: 12px;
  }
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .pagination.menu a {
  text-decoration: none;
  color: #000;
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .pagination.menu .active {
  background-color: var(--pagination-colors);
  color: #ffffff;
  border-radius: 4px;
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .pagination.menu .previous.disabled {
  opacity: 0.5;
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .pagination.menu .next.disabled {
  opacity: 0.5;
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .paginate_button,
.gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .paginate_button.disabled,
.gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .ellipsis {
  font-size: 14px;
  border: none;
  /* margin: 0;
  padding: 9px 16px; */
  margin: 5px;
  padding: 5px 11px 5px 11px;
  font-weight: bold;
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .paginate_button:hover,
.gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .paginate_button.disabled:hover,
.gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .ellipsis:hover {
  background-color: rgba(0, 0, 0, 0.03);
  border-color: rgba(179, 179, 179, 0.3607843137);
  color: rgba(56, 13, 13, 0.787) !important;
}
@media screen and (max-width: 770px) {
  .gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .paginate_button,
  .gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .paginate_button.disabled,
  .gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .ellipsis {
    padding: 6px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .paginate_button,
  .gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .paginate_button.disabled,
  .gswpts_style-4.gswpts_style-4.gswpts_style-4 .simple-pagination-active .ellipsis {
    padding: 3px 6px !important;
    font-size: 12px;
  }
}
.gswpts_style-4.gswpts_style-4.gswpts_style-4 .paginate_button.item:nth-child(n+5):not(.next):not(.current):not(.active):not(.disabled):not(:nth-last-child(-n+2)) {
  display: none;
}

.gswpts_style-5.gswpts_style-5 {
  --header-bg-color: #F2F2F2;
  --header-text-color: #333333;
  --body-bg-color: #ffffff;
  --body-text-color: #000000;
  --hover-bg-color: #bdcfe4;
  --border-color: #e4e1e1;
  --pagination-colors: #2F80ED;
  --border-radius: 10px;
}
.gswpts_style-5.gswpts_style-5 table {
  border-collapse: separate;
}
.gswpts_style-5.gswpts_style-5 table td,
.gswpts_style-5.gswpts_style-5 table th {
  border: 1px solid var(--border-color);
}
.gswpts_style-5.gswpts_style-5 table th.thead-item.expanded_style {
  text-align: left;
}
.gswpts_style-5.gswpts_style-5 table thead th {
  text-transform: uppercase;
  background: var(--header-bg-color);
  color: var(--header-text-color);
}
.gswpts_style-5.gswpts_style-5 table tbody tr td {
  color: var(--body-text-color) !important;
}
.gswpts_style-5.gswpts_style-5 table td {
  border-top: none;
  background-color: var(--body-bg-color);
}
.gswpts_style-5.gswpts_style-5 table td,
.gswpts_style-5.gswpts_style-5 table th {
  border-right: 1px solid var(--border-color);
}
.gswpts_style-5.gswpts_style-5 table td:last-child,
.gswpts_style-5.gswpts_style-5 table th:last-child {
  border-right: 1px solid var(--border-color);
}
.gswpts_style-5.gswpts_style-5 table thead th:first-child {
  border-radius: var(--border-radius) 0 0 0;
}
.gswpts_style-5.gswpts_style-5 table thead th:last-child {
  border-radius: 0 var(--border-radius) 0 0;
}
.gswpts_style-5.gswpts_style-5 table tr:last-child td:first-child {
  border-radius: 0 0 0 var(--border-radius);
}
.gswpts_style-5.gswpts_style-5 table tr:last-child td:last-child {
  border-radius: 0 0 var(--border-radius) 0;
}
.gswpts_style-5.gswpts_style-5 table.ui.celled.display {
  border: none;
}
.gswpts_style-5.gswpts_style-5 .sorting.sorting.sorting::after {
  color: #000;
}
@media screen and (max-width: 768px) {
  .gswpts_style-5.gswpts_style-5.collapse_style .sorting.sorting.sorting::after {
    margin-right: 8px;
  }
}
@media screen and (max-width: 768px) {
  .gswpts_style-5.gswpts_style-5.collapse_style thead th:first-child {
    border-radius: 10px 10px 0 0;
    border-right: none;
  }
  .gswpts_style-5.gswpts_style-5.collapse_style thead th:last-child {
    border-radius: 0;
    border-left: none;
  }
  .gswpts_style-5.gswpts_style-5.collapse_style tbody tr:last-child td:first-child {
    border-radius: 0;
  }
  .gswpts_style-5.gswpts_style-5.collapse_style tbody tr:last-child td:last-child {
    border-radius: 0 0 10px 10px;
  }
}
@media screen and (max-width: 768px) {
  .gswpts_style-5.gswpts_style-5.collapse_style th,
  .gswpts_style-5.gswpts_style-5.collapse_style td {
    border-left: 1px solid var(--border-color) !important;
    border-right: 1px solid var(--border-color) !important;
    /* border-left: 1px solid rgba(229, 231, 235, 1) !important;
    border-right: 1px solid rgba(229, 231, 235, 1) !important; */
  }
}
.gswpts_style-5.gswpts_style-5 table.dataTable.row-border tbody th,
.gswpts_style-5.gswpts_style-5 table.dataTable.row-border tbody td,
.gswpts_style-5.gswpts_style-5 table.dataTable.display tbody th,
.gswpts_style-5.gswpts_style-5 table.dataTable.display tbody td {
  border-top: 1px solid rgba(255, 255, 255, 0.15) !important;
}
.gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .pagination.menu {
  padding: 0 !important;
  font-size: 14px;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: none;
  margin: 0 5px;
}
.gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .pagination.menu > *:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
@media screen and (max-width: 991px) {
  .gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .pagination.menu > * {
    padding: 4px 12px !important;
  }
}
@media screen and (max-width: 770px) {
  .gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .pagination.menu > * {
    padding: 1px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .pagination.menu > * {
    padding: 1px 6px !important;
    font-size: 12px;
  }
}
.gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .pagination.menu a {
  text-decoration: none;
}
.gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .pagination.menu .active {
  background-color: var(--pagination-colors);
  color: #ffffff;
}
.gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .pagination.menu .previous.disabled {
  opacity: 0.5;
}
.gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .pagination.menu .next.disabled {
  opacity: 0.5;
}
.gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .paginate_button,
.gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .paginate_button.disabled,
.gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .ellipsis {
  font-size: 14px;
  border: none;
  /* margin: 0;
  padding: 9px 16px; */
  margin: 5px;
  padding: 5px 11px 5px 11px;
  font-weight: bold;
}
.gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .paginate_button:hover,
.gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .paginate_button.disabled:hover,
.gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .ellipsis:hover {
  background-color: rgba(0, 0, 0, 0.03);
  border-color: rgba(179, 179, 179, 0.3607843137);
  color: rgba(56, 13, 13, 0.787) !important;
}
@media screen and (max-width: 770px) {
  .gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .paginate_button,
  .gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .paginate_button.disabled,
  .gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .ellipsis {
    padding: 6px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .paginate_button,
  .gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .paginate_button.disabled,
  .gswpts_style-5.gswpts_style-5 .tailwind-pagination-active .ellipsis {
    padding: 3px 6px !important;
    font-size: 12px;
  }
}
.gswpts_style-5.gswpts_style-5 .paginate_button.item:nth-child(n+5):not(.next):not(.current):not(.active):not(.disabled):not(:nth-last-child(-n+2)) {
  display: none;
}

.gswpts_style-6.gswpts_style-6 {
  --border-radius: 10px;
  --header-bg-color: #E5F1FF;
  --header-text-color: #0f0f0f;
  --body-bg-color-even: #EBF4FF;
  --body-bg-color-odd: #ffffff;
  --hover-bg-color: #bdcfe4;
  --body-text-color: #000000;
  --pagination-colors: #2BBAE8;
}
.gswpts_style-6.gswpts_style-6 table {
  border-collapse: separate;
}
.gswpts_style-6.gswpts_style-6 table th {
  background: var(--header-bg-color);
  color: var(--header-text-color);
  border-top: none;
  border-left: none;
  border-right: none;
  text-align: left;
}
.gswpts_style-6.gswpts_style-6 table tbody tr td {
  color: var(--body-text-color) !important;
}
.gswpts_style-6.gswpts_style-6 table td:first-child {
  border-right: none;
}
.gswpts_style-6.gswpts_style-6 table td:last-child {
  border-left: none;
}
.gswpts_style-6.gswpts_style-6 table td:not(:first-child, :last-child) {
  border-left: none;
  border-right: none;
}
.gswpts_style-6.gswpts_style-6 table td {
  border-top: none;
  border-bottom: none;
  border-left: 0.5px solid #ffffff;
  border-right: 0.5px solid #ffffff;
}
.gswpts_style-6.gswpts_style-6 table tr:nth-child(even) > td {
  background-color: var(--body-bg-color-even);
}
.gswpts_style-6.gswpts_style-6 table tr:nth-child(odd) > td {
  background-color: var(--body-bg-color-odd);
}
.gswpts_style-6.gswpts_style-6 table tbody tr:hover td {
  background-color: var(--hover-bg-color);
}
.gswpts_style-6.gswpts_style-6 table.ui.celled.display {
  border: none;
}
.gswpts_style-6.gswpts_style-6 .sorting.sorting.sorting::after {
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .gswpts_style-6.gswpts_style-6.collapse_style .sorting.sorting.sorting::after {
    margin-right: 8px;
  }
}
@media screen and (max-width: 768px) {
  .gswpts_style-6.gswpts_style-6.collapse_style thead th:first-child {
    border-radius: 10px 10px 0 0;
    border-right: none;
  }
  .gswpts_style-6.gswpts_style-6.collapse_style thead th:last-child {
    border-radius: 0;
    border-left: none;
  }
  .gswpts_style-6.gswpts_style-6.collapse_style tbody tr:last-child td:first-child {
    border-radius: 0;
  }
  .gswpts_style-6.gswpts_style-6.collapse_style tbody tr:last-child td:last-child {
    border-radius: 0 0 10px 10px;
  }
}
.gswpts_style-6.gswpts_style-6 table.dataTable.row-border tbody th,
.gswpts_style-6.gswpts_style-6 table.dataTable.row-border tbody td,
.gswpts_style-6.gswpts_style-6 table.dataTable.display tbody th,
.gswpts_style-6.gswpts_style-6 table.dataTable.display tbody td {
  border-top: 1px solid rgba(255, 255, 255, 0.15) !important;
}
.gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .pagination.menu {
  padding: 0 !important;
  font-size: 14px;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: none;
  margin: 0 5px;
}
.gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .pagination.menu > *:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
@media screen and (max-width: 991px) {
  .gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .pagination.menu > * {
    padding: 4px 12px !important;
  }
}
@media screen and (max-width: 770px) {
  .gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .pagination.menu > * {
    padding: 1px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .pagination.menu > * {
    padding: 1px 6px !important;
    font-size: 12px;
  }
}
.gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .pagination.menu a {
  text-decoration: none;
  color: #000;
}
.gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .pagination.menu .active {
  background-color: var(--pagination-colors);
  color: #ffffff;
  border-radius: 4px;
}
.gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .pagination.menu .previous.disabled {
  opacity: 0.5;
}
.gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .pagination.menu .next.disabled {
  opacity: 0.5;
}
.gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .paginate_button,
.gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .paginate_button.disabled,
.gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .ellipsis {
  font-size: 14px;
  border: none;
  /*  margin: 0;
  padding: 9px 16px; */
  margin: 5px;
  padding: 5px 11px 5px 11px;
  font-weight: bold;
}
.gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .paginate_button:hover,
.gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .paginate_button.disabled:hover,
.gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .ellipsis:hover {
  background-color: rgba(0, 0, 0, 0.03);
  border-color: rgba(179, 179, 179, 0.3607843137);
  color: rgba(56, 13, 13, 0.787) !important;
}
@media screen and (max-width: 770px) {
  .gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .paginate_button,
  .gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .paginate_button.disabled,
  .gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .ellipsis {
    padding: 6px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .paginate_button,
  .gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .paginate_button.disabled,
  .gswpts_style-6.gswpts_style-6 .tailwind-pagination-active .ellipsis {
    padding: 3px 6px !important;
    font-size: 12px;
  }
}
.gswpts_style-6.gswpts_style-6 .paginate_button.item:nth-child(n+5):not(.next):not(.current):not(.active):not(.disabled):not(:nth-last-child(-n+2)) {
  display: none;
}

.gswpts_style-7.gswpts_style-7 {
  --header-bg-color: #8880F8;
  --header-text-color: #ffffff;
  --body-bg-color: #34344C;
  --body-text-color: #ffffff;
  --border-radius: 10px;
  --hover-bg-color: #7e78d3;
  --pagination-colors: #34344C;
  /* #bottom_options #create_tables_paginate#create_tables_paginate {
      margin-left: 50%;
  } */
}
.gswpts_style-7.gswpts_style-7 table thead th {
  background-color: var(--header-bg-color);
  color: var(--header-text-color);
  border: none;
}
.gswpts_style-7.gswpts_style-7 table tbody tr td {
  background-color: var(--body-bg-color);
  color: var(--body-text-color) !important;
  border: none;
}
.gswpts_style-7.gswpts_style-7 table th.thead-item.expanded_style {
  text-align: left;
}
.gswpts_style-7.gswpts_style-7 table thead th {
  border: none;
}
.gswpts_style-7.gswpts_style-7 table thead th:first-child {
  border-radius: var(--border-radius) 0 0 0;
}
.gswpts_style-7.gswpts_style-7 table thead th:last-child {
  border-radius: 0 var(--border-radius) 0 0;
}
.gswpts_style-7.gswpts_style-7 table tr:last-child td:first-child {
  border-radius: 0 0 0 var(--border-radius);
}
.gswpts_style-7.gswpts_style-7 table tr:last-child td:last-child {
  border-radius: 0 0 var(--border-radius) 0;
}
.gswpts_style-7.gswpts_style-7 table thead tr:hover td,
.gswpts_style-7.gswpts_style-7 table thead tr:hover th,
.gswpts_style-7.gswpts_style-7 table tbody tr:hover td,
.gswpts_style-7.gswpts_style-7 table tbody tr:hover th {
  background-color: var(--hover-bg-color);
}
.gswpts_style-7.gswpts_style-7 table a {
  color: orange;
}
.gswpts_style-7.gswpts_style-7 table a:hover {
  color: #2ecc40;
}
.gswpts_style-7.gswpts_style-7 table .sorting.sorting.sorting::after {
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .gswpts_style-7.gswpts_style-7.collapse_style .sorting.sorting.sorting::after {
    margin-right: 8px;
  }
}
.gswpts_style-7.gswpts_style-7 table.ui.celled.display {
  border: none;
}
.gswpts_style-7.gswpts_style-7 .simple-pagination-active .pagination.menu {
  padding: 0 !important;
  font-size: 14px;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: none;
  margin: 0 5px;
}
.gswpts_style-7.gswpts_style-7 .simple-pagination-active .pagination.menu > *:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
@media screen and (max-width: 991px) {
  .gswpts_style-7.gswpts_style-7 .simple-pagination-active .pagination.menu > * {
    padding: 4px 12px !important;
  }
}
@media screen and (max-width: 770px) {
  .gswpts_style-7.gswpts_style-7 .simple-pagination-active .pagination.menu > * {
    padding: 1px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_style-7.gswpts_style-7 .simple-pagination-active .pagination.menu > * {
    padding: 1px 6px !important;
    font-size: 12px;
  }
}
.gswpts_style-7.gswpts_style-7 .simple-pagination-active .pagination.menu a {
  text-decoration: none;
  color: #000;
}
.gswpts_style-7.gswpts_style-7 .simple-pagination-active .pagination.menu .active {
  background-color: var(--pagination-colors);
  color: #ffffff;
  border-radius: 4px;
}
.gswpts_style-7.gswpts_style-7 .simple-pagination-active .pagination.menu .previous.disabled {
  opacity: 0.5;
}
.gswpts_style-7.gswpts_style-7 .simple-pagination-active .pagination.menu .next.disabled {
  opacity: 0.5;
}
.gswpts_style-7.gswpts_style-7 .simple-pagination-active .paginate_button,
.gswpts_style-7.gswpts_style-7 .simple-pagination-active .paginate_button.disabled,
.gswpts_style-7.gswpts_style-7 .simple-pagination-active .ellipsis {
  font-size: 14px;
  border: none;
  /* margin: 0;
  padding: 9px 16px; */
  margin: 5px;
  padding: 5px 11px 5px 11px;
  font-weight: bold;
}
.gswpts_style-7.gswpts_style-7 .simple-pagination-active .paginate_button:hover,
.gswpts_style-7.gswpts_style-7 .simple-pagination-active .paginate_button.disabled:hover,
.gswpts_style-7.gswpts_style-7 .simple-pagination-active .ellipsis:hover {
  background-color: rgba(0, 0, 0, 0.03);
  border-color: rgba(179, 179, 179, 0.3607843137);
  color: rgba(56, 13, 13, 0.787) !important;
}
@media screen and (max-width: 770px) {
  .gswpts_style-7.gswpts_style-7 .simple-pagination-active .paginate_button,
  .gswpts_style-7.gswpts_style-7 .simple-pagination-active .paginate_button.disabled,
  .gswpts_style-7.gswpts_style-7 .simple-pagination-active .ellipsis {
    padding: 6px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_style-7.gswpts_style-7 .simple-pagination-active .paginate_button,
  .gswpts_style-7.gswpts_style-7 .simple-pagination-active .paginate_button.disabled,
  .gswpts_style-7.gswpts_style-7 .simple-pagination-active .ellipsis {
    padding: 3px 6px !important;
    font-size: 12px;
  }
}
.gswpts_style-7.gswpts_style-7 .paginate_button.item:nth-child(n+5):not(.next):not(.current):not(.active):not(.disabled):not(:nth-last-child(-n+2)) {
  display: none;
}

.gswpts_style-8.gswpts_style-8 {
  --header-bg-color: #E0E7FF;
  --header-text-color: #312E81;
  --border-radius: 10px;
  --body-bg-color: #ffffff;
  --body-text-color: #333333;
  --body-text-color-col-1: #333333;
  --body-text-color-rest: #6B7280;
  --hover-bg-color: #e4e9f8;
  --border-color: #EEF2FF;
  --pagination-colors: #5C51E0;
}
.gswpts_style-8.gswpts_style-8 table {
  border-collapse: unset;
}
.gswpts_style-8.gswpts_style-8 table thead th,
.gswpts_style-8.gswpts_style-8 table tbody tr td {
  background-color: var(--header-bg-color);
  color: var(--header-text-color);
  border: none;
}
.gswpts_style-8.gswpts_style-8 table tbody tr td {
  background-color: var(--body-bg-color);
  color: var(--body-text-color);
}
.gswpts_style-8.gswpts_style-8 table th.thead-item.expanded_style {
  text-align: left;
}
.gswpts_style-8.gswpts_style-8 table tbody td:first-child {
  color: var(--body-text-color-col-1);
}
.gswpts_style-8.gswpts_style-8 table tbody td:not(:first-child) {
  color: var(--body-text-color-rest);
  font-weight: 400;
}
.gswpts_style-8.gswpts_style-8 table thead th {
  border: none;
  text-transform: uppercase;
  font-weight: 600;
}
.gswpts_style-8.gswpts_style-8 table thead th:first-child {
  border-radius: var(--border-radius) 0 0 0;
}
.gswpts_style-8.gswpts_style-8 table thead th:last-child {
  border-radius: 0 var(--border-radius) 0 0;
}
.gswpts_style-8.gswpts_style-8 table tr:last-child td:first-child {
  border-radius: 0 0 0 var(--border-radius);
}
.gswpts_style-8.gswpts_style-8 table tr:last-child td:last-child {
  border-radius: 0 0 var(--border-radius) 0;
}
.gswpts_style-8.gswpts_style-8 table tbody tr td:first-child {
  border-left: 2px solid var(--border-color);
}
.gswpts_style-8.gswpts_style-8 table tbody tr td:last-child {
  border-right: 2px solid var(--border-color);
}
.gswpts_style-8.gswpts_style-8 table tbody tr:last-child td {
  border-bottom: 2px solid var(--border-color);
}
.gswpts_style-8.gswpts_style-8 table thead tr:hover td,
.gswpts_style-8.gswpts_style-8 table thead tr:hover th,
.gswpts_style-8.gswpts_style-8 table tbody tr:hover td,
.gswpts_style-8.gswpts_style-8 table tbody tr:hover th {
  background-color: var(--hover-bg-color);
}
.gswpts_style-8.gswpts_style-8 table a {
  color: orange;
}
.gswpts_style-8.gswpts_style-8 table a:hover {
  color: #2ecc40;
}
.gswpts_style-8.gswpts_style-8 table .sorting.sorting.sorting::after {
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .gswpts_style-8.gswpts_style-8.collapse_style .sorting.sorting.sorting::after {
    margin-right: 8px;
  }
}
.gswpts_style-8.gswpts_style-8 table.ui.celled.display {
  border: none;
}
.gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .pagination.menu {
  padding: 0 !important;
  font-size: 14px;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: none;
  margin: 0 5px;
}
.gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .pagination.menu > *:hover {
  background-color: rgba(0, 0, 0, 0.03);
}
@media screen and (max-width: 991px) {
  .gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .pagination.menu > * {
    padding: 4px 12px !important;
  }
}
@media screen and (max-width: 770px) {
  .gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .pagination.menu > * {
    padding: 1px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .pagination.menu > * {
    padding: 1px 6px !important;
    font-size: 12px;
  }
}
.gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .pagination.menu a {
  text-decoration: none;
}
.gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .pagination.menu .active {
  background-color: var(--pagination-colors);
  color: #ffffff;
}
.gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .pagination.menu .previous.disabled {
  opacity: 0.5;
}
.gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .pagination.menu .next.disabled {
  opacity: 0.5;
}
.gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .paginate_button,
.gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .paginate_button.disabled,
.gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .ellipsis {
  font-size: 14px;
  border: none;
  /* margin: 0;
  padding: 9px 16px; */
  margin: 5px;
  padding: 5px 11px 5px 11px;
  font-weight: bold;
}
.gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .paginate_button:hover,
.gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .paginate_button.disabled:hover,
.gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .ellipsis:hover {
  background-color: rgba(0, 0, 0, 0.03);
  border-color: rgba(179, 179, 179, 0.3607843137);
  color: rgba(56, 13, 13, 0.787) !important;
}
@media screen and (max-width: 770px) {
  .gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .paginate_button,
  .gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .paginate_button.disabled,
  .gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .ellipsis {
    padding: 6px 9px !important;
  }
}
@media screen and (max-width: 420px) {
  .gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .paginate_button,
  .gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .paginate_button.disabled,
  .gswpts_style-8.gswpts_style-8 .tailwind-pagination-active .ellipsis {
    padding: 3px 6px !important;
    font-size: 12px;
  }
}
.gswpts_style-8.gswpts_style-8 .paginate_button.item:nth-child(n+5):not(.next):not(.current):not(.active):not(.disabled):not(:nth-last-child(-n+2)) {
  display: none;
}